<template>
  <div>
    <HomePageTop ref="head" />
    <Dialog ref="dialog" :config="config" class="textareaBar">
      <textarea
        v-if="diaCon == 'chuliyijian'"
        class="chuliyijian"
        :placeholder="$fanyi('请输入处理意见')"
        v-model="dealTxt"
      ></textarea>
    </Dialog>
    <Dialog ref="dialog2" :config="config2">
      <div>
        <div class="lookPicBox">
          <img
            src="../../../../../assets/user-img/left.svg"
            alt=""
            @click="imgChe('-')"
          />

          <el-image
            class="bigImg"
            v-if="lookImgs.length != 0"
            :src="lookImgs[lookNum].image"
            :preview-src-list="[lookImgs[lookNum].image]"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
          <img
            src="../../../../../assets/user-img/right@2x.svg"
            alt=""
            @click="imgChe('+')"
          />
        </div>
        <p class="imgSubscript">{{ lookImgsAct }}/{{ lookImgs.length }}</p>
      </div>
    </Dialog>
    <div class="main">
      <div class="title">
        <h1>{{ $fanyi("问题商品明细") }}：</h1>
        <!-- <el-button
          v-show="form.status && ['待处理'].indexOf(form.status) != -1"
          type="primary"
          @click="client_aswer('all')"
          >{{ $fanyi("统一回复") }}</el-button
        > -->
      </div>
      <div class="sn">
        <span
          >{{ $fanyi("订单号") }}：
          <span
            class="link"
            @click="
              $fun.routerToPage(
                '/OrderDetails?type=offer&order_sn=' + $route.query.order_sn,
                true
              )
            "
            >{{ $route.query.order_sn }}</span
          ></span
        >
        <button
          class="contactCustomerService"
          v-show="form.status && ['待处理'].indexOf(form.status) != -1"
          type="primary"
          @click="client_aswer('all')"
        >
          {{ $fanyi("批量回复") }}
        </button>
        <span v-show="form.status && ['待处理'].indexOf(form.status) == -1">
          {{ $fanyi("联系负责人") }}：
          <img
            @click="tocaht"
            style="cursor: pointer"
            :src="require('@/assets/user-img/contacts.svg')"
            alt=""
          />
        </span>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{
          'text-align': 'center',
          background: '#F0F0F0',
          color: 'black',
          'font-size': '14px',
          'font-weight': '500',
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" class="checkedbox">
        </el-table-column>
        <el-table-column type="index" width="40" label="Nº"> </el-table-column>
        <el-table-column :label="$fanyi('形象')" width="80">
          <template slot-scope="scope">
            <div>
              <el-popover placement="right" trigger="hover">
                <img
                  :src="scope.row.order_detail.pic"
                  alt=""
                  style="width: 300px; height: 300px"
                />

                <el-image
                  slot="reference"
                  class="smallImg"
                  @click="
                    $fun.toCommodityDetails(scope.row.order_detail.goods_id)
                  "
                  :src="scope.row.order_detail.pic"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                  <div slot="placeholder">
                    <i class="el-icon-loading"></i>
                  </div>
                </el-image>
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$fanyi('属性')" width="120">
          <template slot-scope="scope">
            <el-popover placement="bottom" trigger="hover">
              <div class="goodsDetailAll">
                <div
                  class="goodsDetailAllOneBox"
                  v-for="(detailItem, detailIndex) in scope.row.order_detail
                    .detail"
                  :key="detailIndex"
                  :title="detailItem.key + ':' + detailItem.value"
                >
                  <h1 class="detailTitle">{{ detailItem.key }}</h1>
                  <p>
                    <span class="detailBody">{{ detailItem.value }}</span>
                  </p>
                </div>
              </div>

              <div class="detailGoodsBox" slot="reference">
                <p
                  class="detailGoods"
                  v-for="(item, index) in scope.row.order_detail.detail"
                  :key="index"
                  :title="item.key + ':' + item.value"
                >
                  {{ item.key }}:{{ item.value }}
                </p>
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column property="order_detail.price" label="€/u" width="80">
          <template slot-scope="scope">
            <b> {{ scope.row.order_detail.price }}€ </b>
          </template>
        </el-table-column>
        <el-table-column
          property="order_detail.subtotal"
          :label="$fanyi('小计')"
          width="80"
        >
          <template slot-scope="scope">
            <b> {{ scope.row.order_detail.subtotal }}€ </b>
          </template>
        </el-table-column>
        <!-- <el-table-column
          property="order_detail.confirm_freight"
          :label="$fanyi('国内运费')"
          width="86"
        ></el-table-column> -->
        <el-table-column
          property="name"
          :label="$fanyi('附加的服务')"
          width="140"
        >
          <template slot-scope="scope">
            <el-popover placement="bottom" width="630" trigger="hover">
              <el-table
                :data="scope.row.order_detail.option"
                :header-cell-style="{
                  'text-align': 'center',
                }"
                :cell-style="{ 'text-align': 'center' }"
                size="mini"
              >
                <el-table-column property="tag" label=""></el-table-column>
                <el-table-column property="name" :label="$fanyi('名称')">
                  <template slot-scope="scope">
                    <div
                      style="
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                      :title="scope.row.name_translate"
                    >
                      {{ scope.row.name_translate }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  property="num"
                  :label="$fanyi('数量')"
                ></el-table-column>
                <el-table-column
                  property="price"
                  :label="$fanyi('单价')"
                  width="130px"
                >
                  <template slot-scope="scope">
                    <div>
                      {{ $fun.RMBNumSegmentation(scope.row.price) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column property="price" :label="$fanyi('总价')">
                  <template slot-scope="scope">
                    <div>
                      {{
                        $fun.RMBNumSegmentation(
                          $fun.roundNumber(scope.row.price * scope.row.num, 2)
                        )
                      }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column>
                  <template slot-scope="scope">
                    <div class="fbaBox">
                      <el-popover
                        placement="bottom"
                        width="400"
                        trigger="hover"
                      >
                        {{ scope.row.fba }}
                        <span slot="reference">{{ scope.row.fba }}</span>
                      </el-popover>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

              <div class="showOption" slot="reference">
                <div class="showOptionCon">
                  {{ $fanyi("看到细节") }}
                </div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column :label="$fanyi('评论')" width="160">
          <template slot-scope="scope">
            <div>
              <div class="ramark client_deal hui">
                {{ scope.row.order_detail.client_remark }}
              </div>
            </div>
          </template></el-table-column
        >
        <el-table-column :label="$fanyi('问题图像')">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.problem_goods_image.length > 0"
              class="lookPic"
              @click.native="lookPicBoxOpen(scope.row.problem_goods_image)"
              :src="scope.row.problem_goods_image[0].image"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
              <div slot="placeholder">
                <i class="el-icon-loading"></i>
              </div>
            </el-image>
            <span v-else>——</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          property="num"
          :label="$fanyi('问题条数')"
          width="80"
        ></el-table-column> -->
        <el-table-column
          property="order_detail.num"
          :label="$fanyi('问题产品')"
        >
          <template slot-scope="scope">
            <b>
              {{ scope.row.order_detail.num }}
            </b>
          </template>
        </el-table-column>
        <el-table-column
          property="description_translate"
          :label="$fanyi('问题详情')"
          width="160"
        >
          <template slot-scope="scope">
            <div>
              <div class="ramark client_deal hui">
                {{ scope.row.description_translate }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          property="client_deal"
          :label="$fanyi('处理意见')"
          width="160"
        >
          <template slot-scope="scope">
            <div>
              <!-- <p class="ramarkTime">{{ scope.row.client_deal_at }}</p> -->
              <textarea
                class="ramark client_deal"
                :class="{ hui: form.status != '待处理' }"
                @click="
                  ['待处理'].indexOf(form.status) != -1
                    ? client_aswer('one', scope.row)
                    : ''
                "
                style="cursor: pointer"
                v-model="scope.row.client_deal"
                :placeholder="$fanyi('请输入处理意见')"
                readonly
              >
              </textarea>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <FootVue style="margin-top: 80px"></FootVue>
  </div>
</template>

<script>
import Dialog from "../../../../../components/public/Dialog";
import FootVue from "../../../../../components/foot/Foot.vue";
import HomePageTop from "../../../../../components/head/HomePageTop.vue";
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      form: {
        order_sn: this.$route.query.order_sn,
        status: this.$fun.fromCode(this.$route.query.status) || "", //后端问题产品详情接口只设置了接收 待处理/处理中/已处理 其他状态也传这三个
      },
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("事件列表"),
        btnTxt: [this.$fanyi("发送")],
      },
      config2: {
        top: "20vh",
        width: "1200px",
        title: this.$fanyi("问题图片"),
        btnTxt: [],
      },
      lookImgs: [],
      lookImgsAct: 1,
      lookNum: 0,
      bigImgs: [],
      dealTxt: "",
      diaCon: "chuliyijian",
    };
  },
  components: {
    HomePageTop,
    Dialog,
    FootVue,
  },
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    // 打开聊天窗口
    tocaht() {
      this.$fun.clickJs();
    },
    getData() {
      if (!this.$route.query.token) {
        // return
        let datas = {
          order_sn: this.form.order_sn,
          status: this.form.status,
          token: this.$route.query.token,
        };

        this.$api.problemGoodsOrderDetail(datas).then((res) => {
          if (res.code != 0) return;
          this.tableData = res.data;
          this.dataProcessing();
        });
      } else {
        this.$api
          .notLoginorderDetail({ token: this.$route.query.token })
          .then((res) => {
            //console.log("problemGoodsOrderList", res);
            if (res.code != 0) return;
            this.tableData = res.data;
            this.form.status = "待处理"; //后台发给客户的链接的只能是待处理状态

            this.dataProcessing();
          });
      }
    },
    // 数据处理
    dataProcessing() {
      this.tableData.forEach((item) => {
        item.order_detail.detail = JSON.parse(item.order_detail.detail);
        item.order_detail.option = JSON.parse(item.order_detail.option);
      });
    },
    // 打开查看图片弹窗
    lookPicBoxOpen(imgs) {
      this.lookImgs = imgs;
      this.lookNum = 0;
      this.$refs.dialog2.open("");
    },
    // 图片弹窗切换图片
    imgChe(type) {
      if (type == "+") {
        if (this.lookNum < this.lookImgs.length - 1) {
          this.lookNum++;
          this.lookImgsAct++;
        } else {
          this.lookNum = 0;
          this.lookImgsAct = 1;
        }
      }
      if (type == "-") {
        if (this.lookNum > 0) {
          this.lookNum--;
          this.lookImgsAct--;
        } else {
          this.lookNum = this.lookImgs.length - 1;
          this.lookImgsAct = this.lookImgs.length;
        }
      }
      this.$forceUpdate();
    },
    // 打开客户回复窗口
    client_aswer(type, row) {
      this.diaCon = "chuliyijian";

      this.$refs.dialog.open(
        "",
        async () => {
          let ids = [];
          if (type == "all") {
            this.multipleSelection.forEach((item) => {
              ids.push(item.id);
            });
          } else {
            ids = [row.id];
          }
          ids = ids.join(",");
          // return //console.log(this.dealTxt);

          let res;
          if (!this.$route.query.token) {
            res = await this.$api
              .problemGoodsClientDeal({
                ids: ids,
                deal: this.dealTxt,
              })
              .then((res) => {
                //console.log("problemGoodsClientDeal", res);
                if (res.code != 0) return;
                this.$store.dispatch("sidebarNumList");
                this.$message.success(this.$fanyi("操作成功"));
                this.getData();
              });
          } else {
            res = await this.$api
              .notLoginclientDeal({
                token: this.$route.query.token,
                ids: ids,
                deal: this.dealTxt,
              })
              .then((res) => {
                //console.log("problemGoodsClientDeal", res);
                if (res.code != 0) return;
                this.$store.dispatch("sidebarNumList");
                this.$message.success(this.$fanyi("操作成功"));
                // 提交一条就删除一条数据
                this.getData();
              });
          }
        },
        () => {}
      );
    },
    handleSelectionChange(val) {
      //console.log(
      //   "🚀 ~ file: issueOrderDetail.vue ~ line 51 ~ handleSelectionChange ~ val",
      //   val
      // );
      this.multipleSelection = val;
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../../../../css/mixin.scss";
.main {
  width: 1400px;
  margin: 30px auto 0;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  padding: 30px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    > h1 {
      font-size: 18px;

      font-weight: bold;
      color: #000000;
      line-height: 24px;
    }
  }
  .sn {
    margin-bottom: 20px;
    &,
    > * {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .link {
      color: #212a91;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    img {
      width: 32px;
      height: 32px;
    }
    .contactCustomerService {
      width: 183px;
      height: 33px;
      background: #1c2998;
      border-radius: 4px;
      font-size: 12px;
      color: white;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
.textareaBar {
  /deep/.el-dialog {
    .el-dialog__header {
      border: none;
      padding: 30px 20px;
      .el-icon-close {
        font-size: 20px;
        color: black;
      }
    }
    .el-dialog__body {
      padding: 0 20px;
    }
    .el-dialog__footer {
      padding: 0 20px 38px;
      > div {
        text-align: left !important;
      }
    }
  }
}
/deep/.el-table__header {
  margin-bottom: 23px;
  .cell {
    padding: 0 !important;
  }
}
/deep/tbody {
  border: 1px solid #ac6565;
}
/deep/.el-table__row {
  background: #f9f9f9;
}
/deep/.el-checkbox__input {
  height: 16px;
}
.detailGoodsBox {
  overflow-y: auto;
  height: 66px;
  .detailGoods {
  }
}

p {
  font-size: 12px;

  color: #888888;
  line-height: 20px;
}

/deep/.el-image.lookPic {
  cursor: pointer;
  $imgSize: 80px;
  width: $imgSize;
  height: $imgSize;
  transition: 0.3s;
  text-align: center;
  i {
    font-size: $imgSize/4;
    line-height: $imgSize;
  }
}
.ramarkTime {
  min-height: 20px;
}
.ramark {
  position: relative;
  overflow-y: auto;
  text-align: left;
  display: flex;
  margin: 0px auto;
  width: 140px;
  height: 80px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  // &.client_deal {
  //   border: 1px solid rgba(30, 41, 151, 0.3);
  //   background: rgba(30, 41, 151, 0.04);
  //   height: 80px;
  //   padding: 5px;
  // }
  // &.hui {
  //   border: 1px solid rgb(167, 167, 167);
  //   background: transparent;
  //   cursor: default !important;
  // }
}

/deep/.el-dialog__body {
  padding-bottom: 20px !important;
}
.chuliyijian {
  width: 458px;
  height: 426px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  padding: 20px;
  font-size: 14px;
  line-height: 19px;
  margin: 0 auto;
  display: block;
}

.lookPicBox {
  width: 1120px;
  height: 640px;
  background: #fafafa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  img {
    width: 40px;
    height: 40px;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.3s;
    &:hover {
      opacity: 1;
    }
  }
}
/deep/.el-image.bigImg {
  $imgSize: 600px;
  width: $imgSize;
  height: $imgSize;
  transition: 0.3s;
  text-align: center;
  i {
    font-size: $imgSize/4;
    line-height: $imgSize;
  }
}
/deep/.el-image.smallImg {
  $imgSize: 80px;
  width: $imgSize;
  height: $imgSize;
  transition: 0.3s;
  text-align: center;
  i {
    font-size: $imgSize/4;
    line-height: $imgSize;
  }
}
.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;
  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}
/deep/input[type="checkbox"] {
  height: 0px;
}
.showOptionCon {
  width: 116px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  line-height: 38px;
  font-size: 14px;
  color: #3a3a3a;
}
.fbaBox {
  max-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.imgSubscript {
  text-align: center;
  font-size: 20px;
  color: #000000;
}
</style>
